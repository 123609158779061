import './index.scss'

import React, { FC, ReactNode } from 'react'

type Props = {
  main: string
  body1: string
  body2: string
  children?: ReactNode
}

const MainSection: FC<Props> = ({ main, body1, body2, children }) => {
  return (
    <article className="article">
      <h3 className="article__main">{main}</h3>
      <p className="article__body--1">{body1}</p>
      <p className="heading__body--2">{body2}</p>
      {children}
    </article>
  )
}

export default MainSection
