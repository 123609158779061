import { createApi } from '@reduxjs/toolkit/query/react'

import {
  AuthUserModel,
  LoginParams,
  UserAuthInitModel,
  UserAuthModel
} from '../../models/auth'
import { UserConfigModel } from '../../models/user'
import { fetchBase } from '../baseQuery'

export const authApi = createApi({
  reducerPath: 'authApi',
  keepUnusedDataFor: 0,
  baseQuery: fetchBase(),
  endpoints: builder => ({
    getAuthVerifyCode: builder.mutation<UserAuthInitModel, string>({
      query: phoneNumber => ({
        url: '/auth/code',
        method: 'POST',
        body: {
          phoneNumber,
          noSend: false
        }
      })
    }),
    getAuthToken: builder.mutation<UserAuthModel, LoginParams>({
      query: data => ({
        url: `/auth/verify`,
        method: 'POST',
        body: data
      })
    }),
    getAuthUser: builder.query<AuthUserModel, void>({
      query: () => `/user`
    }),
    getUserConfig: builder.query<UserConfigModel, void>({
      query: () => `/user/config`
    }),
    updateUser: builder.mutation<{ user: AuthUserModel }, { name: string }>({
      query: data => ({
        url: `/user`,
        method: 'PATCH',
        body: data
      })
    })
  })
})

export const {
  useGetAuthTokenMutation,
  useGetAuthVerifyCodeMutation,
  useLazyGetAuthUserQuery,
  useGetUserConfigQuery,
  useUpdateUserMutation
} = authApi
