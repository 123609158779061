import { useCallback, useEffect, useRef } from 'react'

import { setRentalStatus } from '../redux/rental/slice'
import { useDispatch, useSelector } from './useRedux'

const useRentalTimeout = (): void => {
  const dispatch = useDispatch()
  const timeoutRef = useRef<NodeJS.Timeout>()

  const clearFailTimeout = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
  }, [])

  const status = useSelector(state => state.rental.status)

  useEffect(() => {
    if (status.loading) {
      clearFailTimeout()
      timeoutRef.current = setTimeout(() => {
        dispatch(
          setRentalStatus({
            error: 'TIMED_OUT',
            result: undefined,
            loading: false
          })
        )
      }, 15000)
    } else {
      clearFailTimeout()
    }
  }, [status.loading, clearFailTimeout, dispatch])
}

export default useRentalTimeout
