import './App.scss'
import './utils/i18n'

import { Elements } from '@stripe/react-stripe-js'
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js'
import React, { FC, lazy, Suspense, useCallback, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'

import { connectPubSub, unSub } from './api/pubsub'
import LanguageSwitch from './components/LanguageSwitch/LanguageSwitch'
import Loading from './components/Loading/Loading'
import StoreContextProvider, { RentalStatusModel } from './context/storeContext'
import useLocale from './hooks/useLocale'
import { useDispatch, useSelector } from './hooks/useRedux'
import useRentalTimeout from './hooks/useRentalTimeout'
import { useLazyGetAuthUserQuery } from './redux/auth/api'
import { setCampaignFlowLoading, setRentalStatus } from './redux/rental/slice'
import LoadingModal from './screens/appless/CampaignStartScreen/LoadingModal'
import LoadingRoger from './screens/appless/CampaignStartScreen/LoadingRoger'
import LandingScreen from './screens/common/LandingScreen'
import Logout from './screens/common/Logout'
import ConfirmCodeScreen from './screens/eventMode/ConfirmCodeScreen'
import ResultScreen from './screens/eventMode/ResultScreen'
import SignupPhoneNumber from './screens/eventMode/SignupPhoneNumber'
import StartScreenFree from './screens/eventMode/StartScreenFree'
import UpdateUserScreen from './screens/eventMode/UpdateUserScreen'
import { constants } from './utils'

const ErrorScreen = lazy(() => import('./screens/appless/ErrorScreen'))
const FailedScreen = lazy(() => import('./screens/appless/FailedScreen'))
const PaymentMethodScreen = lazy(() => import('./screens/appless/PaymentMethodScreen'))
const CreditCardScreen = lazy(() => import('./screens/appless/CreditCardScreen'))
const OnboardingScreen = lazy(() => import('./screens/common/OnboardingScreen'))
const CompletedScreen = lazy(() => import('./screens/appless/CompletedScreen'))
const ConfirmScreen = lazy(() => import('./screens/appless/ConfirmScreen'))
const HomeScreen = lazy(() => import('./screens/Home/Home'))

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || '')

export const routes = {
  home: '/',
  method: '/method',
  payment: '/payment',
  offline: '/offline',
  error: '/error',
  unavailable: '/unavailable',
  start: '/start/:stationId',
  name: '/name',
  onboarding: '/onboarding',
  starting: '/starting',
  failed: '/failed',
  signup: '/signup',
  verify: '/verify',
  confirm: '/confirm',
  terms: '/terms',
  result: '/result',
  logout: '/logout'
} as const

const ELEMENTS_OPTIONS: StripeElementsOptions = {
  fonts: [
    {
      cssSrc: constants.css
    }
  ]
}

const App: FC = () => {
  const dispatch = useDispatch()

  const [getUser, userRes] = useLazyGetAuthUserQuery()
  const token = useSelector(state => state.auth.token)

  useEffect(() => {
    if (token) {
      getUser()
    }
  }, [token, getUser])

  const setStatus = useCallback(
    (status: RentalStatusModel) => {
      dispatch(setRentalStatus(status))
    },
    [dispatch]
  )

  useEffect(() => {
    if (userRes.data?.id) {
      connectPubSub(userRes.data.id, setStatus, () =>
        dispatch(setCampaignFlowLoading(false))
      )
    }
    return () => unSub()
  }, [userRes.data?.id, setStatus, dispatch])

  const { language, changeLanguage } = useLocale()

  useRentalTimeout()

  return (
    <StoreContextProvider>
      <Elements
        stripe={stripePromise}
        options={{ ...ELEMENTS_OPTIONS, locale: language }}
      >
        <div className="content">
          <Suspense fallback={<div />}>
            <LanguageSwitch language={language} changeLanguage={changeLanguage} />
          </Suspense>
          <LoadingModal>
            <LoadingRoger />
          </LoadingModal>
          <Switch>
            <Route exact path={routes.home}>
              <Suspense fallback={<div />}>
                <HomeScreen />
              </Suspense>
            </Route>
            <Route path={routes.start}>
              <Suspense fallback={<div />}>
                <LandingScreen />
              </Suspense>
            </Route>
            <Route path={routes.verify}>
              <Suspense fallback={<div />}>
                {/* event mode */}
                <ConfirmCodeScreen />
              </Suspense>
            </Route>
            <Route path={routes.signup}>
              <Suspense fallback={<div />}>
                {/* event mode */}
                <SignupPhoneNumber />
              </Suspense>
            </Route>
            <Route path={routes.name}>
              <Suspense fallback={<div />}>
                <UpdateUserScreen />
              </Suspense>
            </Route>
            <Route path={routes.method}>
              <Suspense fallback={<Loading />}>
                <PaymentMethodScreen />
              </Suspense>
            </Route>
            <Route path={routes.payment}>
              <Suspense fallback={<Loading />}>
                <CreditCardScreen />
              </Suspense>
            </Route>
            <Route path={routes.terms}>
              <Suspense fallback={<Loading />}>
                <ConfirmScreen />
              </Suspense>
            </Route>
            <Route path={routes.confirm}>
              <Suspense fallback={<Loading />}>
                <StartScreenFree />
              </Suspense>
            </Route>
            <Route path={routes.offline}>
              <Suspense fallback={<Loading />}>
                <ErrorScreen variant="OFFLINE" />
              </Suspense>
            </Route>
            <Route path={routes.unavailable}>
              <Suspense fallback={<Loading />}>
                <ErrorScreen variant="UNAVAILABLE" />
              </Suspense>
            </Route>
            <Route path={routes.error}>
              <Suspense fallback={<Loading />}>
                <ErrorScreen variant="UNKNOWN" />
              </Suspense>
            </Route>
            <Route path={routes.onboarding}>
              <Suspense fallback={<Loading />}>
                <OnboardingScreen />
              </Suspense>
            </Route>
            <Route path={routes.starting}>
              <Suspense fallback={<Loading />}>
                <ResultScreen />
              </Suspense>
            </Route>
            <Route path={routes.result}>
              <Suspense fallback={<Loading />}>
                <CompletedScreen />
              </Suspense>
            </Route>
            <Route path={routes.failed}>
              <Suspense fallback={<Loading />}>
                <FailedScreen />
              </Suspense>
            </Route>
            <Route path={routes.logout}>
              <Suspense fallback={<Loading />}>
                <Logout />
              </Suspense>
            </Route>
          </Switch>
        </div>
      </Elements>
    </StoreContextProvider>
  )
}
export default App
