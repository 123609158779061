import { isIOS } from 'react-device-detect'

export const validateEmail = (email: string): boolean => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const validatePhone = (phone: string): boolean => {
  const re =
    /^[(]?[+]?[0-9]{1,10}?[)]?[(]?[-\s.]?[0-9]{1,10}[)]?[(]?[-\s.]?[0-9]{1,10}[)]?[(]?[-\s.]?[0-9]{1,10}[)]?$/gm
  return re.test(String(phone).toLowerCase())
}

export const constants = {
  contactEmail: 'mailto:help@brickapp.se',
  iosLink: 'https://apps.apple.com/us/app/brick-powerbank-sharing/id1471658723',
  androidLink: 'https://play.google.com/store/apps/details?id=app.qvik',
  terms: 'https://www.brick.tech/terms',
  css: 'https://fonts.googleapis.com/css?family=Roboto',
  store: 'https://onelink.to/gs8tj3',
  uae: 'Dubai'
} as const

export const getAppLink = (): string => {
  if (isIOS) return constants.iosLink
  return constants.androidLink
}
