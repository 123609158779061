// this is some copy-pasted garbage from the app

export const RentalErrorMessage = {
  STATION_OFFLINE: 'station_offline',
  NO_POWER_BANKS: 'no_power_banks',
  INSUFFICIENT_FUNDS: 'insufficient_funds',
  EXPIRED_CARD: 'expired_card',
  COULD_NOT_CREATE_PAYMENT: 'invalid_card',
  UNEXPECTED_ERROR: 'generic',
  OFFLINE: 'station_offline',
  INVALID_STATION_ID: 'invalid_station_id',
  MAX_POWERBANKS_RENTED: 'max_powerbanks_rented'
} as const

export const parseError = (
  message: string | undefined
): typeof RentalErrorMessage[keyof typeof RentalErrorMessage] => {
  if (!message) return RentalErrorMessage.UNEXPECTED_ERROR
  return (
    RentalErrorMessage[message as keyof typeof RentalErrorMessage] ||
    RentalErrorMessage.UNEXPECTED_ERROR
  )
}

//  const errorResponse = action?.error?.response?.data?.error

/*
  "errors": {
    "station_offline": "This Brick station is offline, let someone know its off!",
    "no_power_banks": "There are no powerbanks at this station, please find another Brick station",
    "insufficient_funds": "Your payment method has insufficient funds",
    "expired_card": "Card expired",
    "invalid_card": "Invalid payment method",
    "generic": "Server error :(",
    "max_powerbanks_rented": "Limit reached! Please return a powerbank to rent a new one",
    "invalid_station_id": "This station seems lost! Get in touch with support and help it find its way"
  },
 */
