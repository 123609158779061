import axios from 'axios'

import { ApiResponse } from '../models/general'
import { PrimerTokenModel } from '../models/payment'
import { PBStatus, StationModel } from '../models/station'
import { UserAuthModel, UserConfigModel } from '../models/user'

const apiKey = process.env.REACT_APP_API_KEY
const baseURL = process.env.REACT_APP_BASE_URL

const authorizedAxios = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': apiKey,
    'app-version': 'WEB:1.6.1'
  }
})

const publicAxios = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': apiKey,
    'app-version': 'WEB:1.6.1'
  }
})

export const checkIfRentable = (station: StationModel): StationModel => {
  const lowBattery = station.slotStatus.includes(PBStatus.LOW_BATTERY)
  if (lowBattery) {
    const powerbanksSortedByCharge = station.powerBanks.sort(
      (a, b) => +a.batteryLevel - +b.batteryLevel
    )
    return {
      ...station,
      highestCharge: +powerbanksSortedByCharge[0].batteryLevel,
      highestChargeSlot: powerbanksSortedByCharge[0].slot
    }
  }
  return station
}

export const getAuthToken = async (
  body: {
    language: string
  } & any
): Promise<ApiResponse<UserAuthModel>> => {
  try {
    const { data } = await publicAxios.post<UserAuthModel>(`/user/appless`, body)
    return { status: true, data }
  } catch (e: any) {
    return { status: false, message: e.response?.data?.message || e.message }
  }
}

export const startRental = async (
  accessToken: string,
  stationId: string
): Promise<ApiResponse<any>> => {
  try {
    const { data } = await authorizedAxios.post<PrimerTokenModel>(
      `/rental`,
      {
        stationId
      },
      {
        headers: {
          authorization: accessToken
        }
      }
    )
    return { status: true, data }
  } catch (e: any) {
    return { status: false, message: e.response?.data?.error?.message || e.message }
  }
}

export const getConfig = async (): Promise<ApiResponse<UserConfigModel>> => {
  try {
    const { data } = await publicAxios.get<UserConfigModel>(`/user/config`)
    return { status: true, data }
  } catch (e: any) {
    return { status: false, message: e.response?.data?.error?.message || e.message }
  }
}

export const attachMethod = async (
  methodId: string,
  accessToken: string
): Promise<ApiResponse<any>> => {
  try {
    const { data } = await authorizedAxios.post(
      'payment/attachMethod',
      { methodId },
      {
        headers: {
          authorization: accessToken
        }
      }
    )
    return { status: true, data }
  } catch (e: any) {
    return { status: false, message: e.response?.data?.error?.message || e.message }
  }
}

export const getLinkData = async (url: string): Promise<string | undefined> => {
  const { data } = await publicAxios.get<{ station: { stationId: string } }>(
    'stations/links',
    {
      params: {
        url
      }
    }
  )
  return data.station.stationId
}

export type ConsumePromoCodeResponse = {
  message: 'Code consumed.'
  creditsRewarded: number
}

export const consumePromoCode = async (
  code: string,
  accessToken: string
): Promise<ConsumePromoCodeResponse> => {
  const { data } = await authorizedAxios.post<ConsumePromoCodeResponse>(
    '/promos/consume',
    {
      code
    },

    {
      headers: {
        authorization: accessToken
      }
    }
  )
  return data
}
