import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

export type Locale = typeof LANGUAGES[number]['value']

export const DEFAULT_LANG = 'en'

export const LANGUAGES = [
  { value: 'en', label: 'English' },
  { value: 'sv', label: 'Swedish' },
  // { value: 'ka', label: 'Georgian' },
  // { value: 'de', label: 'German' },
  // { value: 'tr', label: 'Turkish' },
  // { value: 'es', label: 'Spanish' },
  // { value: 'fr', label: 'French' },
  { value: 'nl', label: 'Dutch' }
] as const

const langs = LANGUAGES.map(l => l.value) as string[]

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: DEFAULT_LANG,
    interpolation: { escapeValue: false },
    detection: {
      convertDetectedLanguage: (loc: string) => {
        const lang = loc.split('-')[0] ?? loc
        return langs.includes(lang) ? lang : DEFAULT_LANG
      }
    }
  })

export default i18n

// function to get the language from a locale string:
export const getLanguage = (locale: string): string => {
  return locale.split('-')[0]
}
