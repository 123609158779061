import React, { FC } from 'react'

type SpacingProps = {
  multiplier?: number
  base?: number
  horizontal?: boolean
}

export const Spacing: FC<SpacingProps> = ({ multiplier, base, horizontal }) => {
  const size = (multiplier || 1) * (base || 8)

  const margin = horizontal ? `0 ${size}px` : `${size}px 0`

  return <div style={{ margin }} />
}
