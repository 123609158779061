import { ChangeEvent, useEffect, useState } from 'react'

import i18n, { Locale } from '../utils/i18n'

const useLocale = () => {
  const [language, setLanguage] = useState<Locale>('en')

  const changeLanguage = (e: ChangeEvent<HTMLSelectElement>) => {
    i18n.changeLanguage(e.target.value)
  }

  useEffect(() => {
    setLanguage(i18n.language as Locale)
    const listener = (lang: Locale) => {
      setLanguage(lang)
    }
    i18n.on('languageChanged', listener)
    return () => i18n.off('languageChanged', listener)
  }, [])

  return {
    language,
    changeLanguage
  }
}

export default useLocale
