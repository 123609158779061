import React, { FC, useEffect } from 'react'

import { useDispatch } from '../../hooks/useRedux'
import { logout } from '../../redux/auth/slice'

const Logout: FC = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(logout())
  }, [dispatch])

  return (
    <div className="station-info">
      <h1>logged out</h1>
    </div>
  )
}

export default Logout
