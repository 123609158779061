export type StationModel = {
  id: string
  market: string
  venueId?: string
  slotStatus: string
  slotTotal: number
  lockByHours: number
  online: boolean
  stationName?: string
  promotion?: Promotion
  pricingModel: PricingModel
  powerBanksAvailable: number
  regionCode: string
  powerBanks: Powerbank[]
  highestCharge?: number
  highestChargeSlot?: string
  venue?: VenueModel
}

type Coordinates = {
  longitude: number
  latitude: number
}

type VenueStation = {
  stationId: string
  stationName: string
}

type OpenClose = { day: number; time: string }

type Period = {
  close: OpenClose
  open: OpenClose
}

export type VenueModel = {
  id: string
  logo?: string
  placeId?: string
  name: string
  market: string // exclude
  streetName: string
  streetNumber: string
  city: string
  country: string
  coordinates: Coordinates
  category: string
  openingHours: Period[]
  openingHoursExtra?: 'EVENT'
  stations: VenueStation[]
  pricingModel: PricingModel
  promotion?: Promotion
  revenueShare: number
  private: boolean
  hidden?: boolean
  autoUpdateOff?: boolean
  status?: 'DELETED' // filter deleted from csv
  organization?: OrganizationModel
}

type Powerbank = {
  batteryLevel: string
  id: string
  slot: string
  returnedAt?: number
  stationId: string
}

export type Promotion = {
  name: 'Free' | 'FreeTime' | 'SuperFree'
  freeTime?: number
  active: boolean
}

export type PricingModel = {
  currency: string
  unlockFee: number
  totalPowerBankCost: number
  maxCostPerDay: number
  chargeInterval: number
  pricePerInterval: number
  chargeType?: ChargeType
  authorizationAmount?: number
}

type ChargeType = 'HALF_HOURLY' | 'HOURLY' | 'DAILY'

type OrganizationModel = {
  id: string
  market: string
  name: string
  campaignBeta?: boolean
}

export enum PBStatus {
  EMPTY_SLOT = '0',
  LOW_BATTERY = '1',
  IS_RENTABLE = '2',
  IS_LOCKED = '3'
}
