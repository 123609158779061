import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { routes } from '../App'
import { StationModel } from '../models/station'

const usePowerbanksUnavailable = (station?: StationModel) => {
  const history = useHistory()
  useEffect(() => {
    if (station) {
      if (!station.online) {
        history.replace(routes.offline)
      } else if (station.powerBanksAvailable === 0) {
        history.replace(routes.unavailable)
      } else if (
        typeof station.highestCharge === 'number' &&
        station.highestCharge <= 70 &&
        station.highestCharge >= 50
      ) {
        // history.push(routes.charging)
      } else {
        // history.push(routes.station)
      }
    }
  }, [station, history])
}

export default usePowerbanksUnavailable
