import 'swiper/swiper.scss'
import 'swiper/modules/navigation/navigation.scss'
import 'swiper/modules/pagination/pagination.scss'

import React from 'react'
import { Keyboard, Mousewheel, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import Images from '../../../assets'

const SLIDE_PAGES = [
  {
    header: 'The Brick',
    body: 'This is a Brick power bank, with cables compatible with most phones and other devices.  ',
    imgSrc: Images.Brick
  },
  {
    header: 'scan to rent',
    body: 'To start renting, use the app and scan the QR code on the station. ',
    imgSrc: Images.Scan
  },
  {
    header: 'charge on-the-go',
    body: 'Pick up a Brick and charge on-the-go.',
    imgSrc: Images.Charge
  },
  {
    header: 'return anywhere',
    body: 'Return the Brick in any of our stations all over your city and beyond.',
    imgSrc: Images.Return
  }
] as const

const renderSlide = (slide: typeof SLIDE_PAGES[number]) => {
  return (
    <SwiperSlide key={slide.imgSrc} className="my-swiper">
      <section>
        <img src={slide.imgSrc} alt="slide-icon" />

        <article className="heading">
          <h1 className="heading--main">{slide.header}</h1>
          <p className="heading--body">{slide.body}</p>
        </article>
      </section>
    </SwiperSlide>
  )
}

const Slider = () => {
  return (
    <section className="slider-wrapper">
      <h3 className="slider-wrapper__main" style={{ color: 'white' }}>
        How Brick Works
      </h3>
      <Swiper
        cssMode
        navigation
        pagination
        mousewheel
        keyboard
        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
        className="my-swiper"
      >
        {SLIDE_PAGES.map(renderSlide)}
      </Swiper>
    </section>
  )
}

export default Slider
