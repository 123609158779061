import './index.scss'
import 'react-phone-input-2/lib/style.css'

import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-input-2'
import { useHistory } from 'react-router-dom'

import { routes } from '../../../App'
import Button from '../../../components/Button/Button'
import { Spacing } from '../../../components/common'
import Header from '../../../components/Header/Header'
import { useDispatch, useSelector } from '../../../hooks/useRedux'
import {
  useGetAuthVerifyCodeMutation,
  useGetUserConfigQuery
} from '../../../redux/auth/api'
import { setPhoneNumber } from '../../../redux/auth/slice'
import { constants } from '../../../utils'

type Props = {}

const SignupPhoneNumber: FC<Props> = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const token = useSelector(state => state.auth.token)
  const [getVerifyCode, verifyResponse] = useGetAuthVerifyCodeMutation()
  const [number, setNumber] = useState('')
  const userConfigRes = useGetUserConfigQuery()

  useEffect(() => {
    if (token) {
      history.push(routes.confirm)
    }
  }, [token, history])

  const sendVerificationCode = () => {
    dispatch(setPhoneNumber(`+${number}`))
    getVerifyCode(`+${number}`)
  }

  useEffect(() => {
    const { data, isLoading } = verifyResponse
    if (!isLoading && data) {
      history.push(routes.verify)
    }
  }, [history, verifyResponse, dispatch])

  return (
    <div className="signup-phone-screen">
      <div className="top-container">
        <Header height={100} white />
      </div>
      <div className="bottom-container">
        <div className="text-container">
          <h1>{t('landing_screen.charge_now')}</h1>
          <Spacing horizontal multiplier={1} />
          <p>{t('signup.header')}</p>
        </div>
        <Spacing multiplier={1} />
        <div>
          <PhoneInput
            inputStyle={{ width: '70vw', height: 47 }}
            country={userConfigRes.data?.userCountry.countryCode?.toLowerCase()}
            inputProps={{
              name: 'phone',
              required: true,
              autoFocus: true
            }}
            enableLongNumbers
            value={number}
            onChange={setNumber}
          />
        </div>
        <Spacing multiplier={1.5} />
        <div className="button-container">
          <Button
            loading={verifyResponse.isLoading}
            label="Continue"
            onClick={sendVerificationCode}
            style={{ backgroundColor: '#FF024A' }}
          />
          <p className="terms">
            {`${t('landing_screen.accept_terms')}\n`}
            <span>
              <a target="_blank" href={constants.terms} rel="noreferrer">
                {t('landing_screen.read_more')}
              </a>
            </span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default SignupPhoneNumber
