import './index.scss'

import React, { FC, ReactNode } from 'react'

type Props = {
  type?: 'button' | 'submit' | 'reset' | undefined
  id?: string
  children: ReactNode
  link?: string
  btnType: string
  onClick?: (() => void) | any
}

const Button: FC<Props> = ({ type, id, children, link, btnType, onClick }) => {
  return (
    <>
      {link && (
        <a
          href={link}
          className={`btn btn--${btnType}`}
          id={id}
          target="_blank"
          rel="noreferrer"
        >
          {children}
        </a>
      )}

      {!link && (
        /* eslint-disable-next-line react/button-has-type */
        <button className={`btn btn--${btnType}`} type={type} id={id} onClick={onClick}>
          {children}
        </button>
      )}
    </>
  )
}

export default Button
