import './index.scss'

import React, { FC, SyntheticEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { routes } from '../../../App'
import Images from '../../../assets'
import AppLinks from '../../../components/AppLinks/AppLinks'
import Button from '../../../components/Button/Button'
import ErrorMessage from '../../../components/Error/Error'
import HelpButton from '../../../components/HelpButton/HelpButton'
import Pricing from '../../../components/Pricing/Pricing'
import useCreateUser from '../../../hooks/useCreateUser'
import usePowerbanksUnavailable from '../../../hooks/usePowerbanksUnavailable'
import { useSelector } from '../../../hooks/useRedux'
import { StationModel } from '../../../models/station'
import { constants } from '../../../utils'

type Props = {
  station: StationModel
}

const StartScreen: FC<Props> = ({ station }) => {
  const history = useHistory()
  const { t } = useTranslation()

  const applessOnly = useSelector(state => state.station.applessOnly)

  const { createUser, isLoading, actionErrorMessage } = useCreateUser()

  usePowerbanksUnavailable(station)

  const onSubmit = (e: SyntheticEvent) => {
    createUser(e, () => history.push(routes.method))
  }

  return (
    <div className="station-info">
      <div className="container-top">
        <img className="station-info-image" src={Images.HowTo} alt="Station" />

        <h1>{t('landing_screen.charge_now')}</h1>
        <Pricing station={station} />
      </div>

      <HelpButton />
      <div className="station-info-button">
        <Button
          loading={isLoading}
          label={t('landing_screen.rent_button')}
          onClick={onSubmit}
          style={{ backgroundColor: '#FF024A' }}
        />
        {actionErrorMessage && <ErrorMessage message={actionErrorMessage} />}
      </div>
      <p className="terms">
        {`${t('landing_screen.accept_terms')} `}
        <a target="_blank" href={constants.terms} rel="noreferrer">
          {t('landing_screen.read_more')}
        </a>
      </p>
      {!applessOnly && <AppLinks />}
      <a
        className="contact"
        target="_blank"
        href={constants.contactEmail}
        rel="noreferrer"
      >
        {t('contact_us')}
      </a>
    </div>
  )
}

export default StartScreen
