import './Index.scss'

import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { routes } from '../../App'

type Props = {}

const HelpButton: FC<Props> = () => {
  const history = useHistory()
  const { t } = useTranslation()

  return (
    <button
      className="help-button"
      type="button"
      onClick={() => history.push(routes.onboarding)}
    >
      <h2>{t('buttons.onboarding')}</h2>
    </button>
  )
}

export default HelpButton
