/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RentalStatusModel } from '../../context/storeContext'
import { rentalApi } from './api'

export type RentalState = {
  timeout?: NodeJS.Timeout
  status: RentalStatusModel
  isLoading: boolean
}

const initialState: RentalState = {
  status: {
    loading: false
  },
  isLoading: false
}

export const slice = createSlice({
  name: 'station',
  initialState,
  reducers: {
    setRentalStatus: (state, action: PayloadAction<RentalStatusModel>) => {
      state.status = action.payload
    },
    setTimeout: (state, action: PayloadAction<NodeJS.Timeout>) => {
      state.timeout = action.payload
    },
    clearTimeout: (state, action: PayloadAction<NodeJS.Timeout>) => {
      state.timeout = action.payload
    },
    setCampaignFlowLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addMatcher(
        rentalApi.endpoints.createRental.matchRejected,
        (state, { payload }) => {
          const message = payload?.data?.error?.message
          state.status = {
            loading: false,
            error: message
          }
        }
      )
      .addMatcher(rentalApi.endpoints.createRental.matchPending, state => {
        state.status = { loading: true }
      })
  }
})

export const { setRentalStatus, setCampaignFlowLoading } = slice.actions

export default slice.reducer
