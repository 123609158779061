import './Index.scss'

import React, { FC } from 'react'

type Props = {
  minHeight?: boolean
}

const Loading: FC<Props> = ({ minHeight }) => (
  <div className={`loading-wrapper ${minHeight && 'loading-wrapper--new-min-height'}`}>
    <div className="loader" />
  </div>
)

export default Loading
