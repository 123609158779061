import './index.scss'

import React, { FC, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Images from '../../../assets'
import Button from '../../../components/Button/Button'
import { Spacing } from '../../../components/common'
import { StoreContext } from '../../../context/storeContext'
import { constants, getAppLink } from '../../../utils'
import { parseError } from '../../../utils/errors'
import { useSelector } from '../../../hooks/useRedux'

const variants = {
  UNKNOWN: {
    img: Images.NoneAvailable,
    imgAlt: 'unknown issue',
    header: 'failed_screen.unknown.header',
    body1: 'failed_screen.unknown.body1',
    body2: 'failed_screen.body2'
  },
  NOT_PICKED_UP: {
    img: Images.Powerbank,
    imgAlt: 'powerbank',
    header: 'failed_screen.not_picked_up.header',
    body1: 'failed_screen.not_picked_up.body1',
    body2: 'failed_screen.body2'
  }
}

export const getErrorProps = (
  error?: string
): typeof variants['UNKNOWN' | 'NOT_PICKED_UP'] => {
  if (error === 'NOT_PICKED_UP_IN_TIME') {
    return {
      img: Images.Powerbank,
      imgAlt: 'powerbank',
      header: 'failed_screen.not_picked_up.header',
      body1: 'failed_screen.not_picked_up.body1',
      body2: 'failed_screen.body2'
    }
  }
  return {
    img: Images.NoneAvailable,
    imgAlt: 'unknown issue',
    header: 'failed_screen.unknown.header',
    body1: `errors.${parseError(error)}`,
    body2: 'failed_screen.body2'
  }
}

type Props = {}

const FailedScreen: FC<Props> = () => {
  const { t } = useTranslation()

  const applessOnly = useSelector(state => state.station.applessOnly)

  const { createRental, rentalStatus } = useContext(StoreContext)

  const [canRetry, setCanRetry] = useState(false)

  useEffect(() => {
    let timeoutRef: NodeJS.Timeout
    if (rentalStatus.loading) {
      setCanRetry(false)
    }
    if (!rentalStatus.loading && rentalStatus.error) {
      timeoutRef = setTimeout(() => {
        setCanRetry(true)
      }, 5000)
    }

    return () => clearTimeout(timeoutRef)
  }, [rentalStatus.error, rentalStatus.loading])

  const data = getErrorProps(rentalStatus.error)
  return (
    <div className="failed-screen-container">
      <div className="top-container">
        <img src={data.img} alt={data.imgAlt} />
      </div>
      <div className="mid-container">
        <h1>{t(data.header)}</h1>
        <p>{t(data.body1)}</p>
        <Button
          label={t('failed_screen.try_again')}
          disabled={!canRetry}
          onClick={createRental}
          style={{ backgroundColor: '#FF024A' }}
        />
        {!applessOnly && (
          <Button
            label={t('failed_screen.accept')}
            onClick={() => window.open(getAppLink(), '_blank')}
            style={{
              backgroundColor: 'transparent',
              border: 'black solid 2px',
              color: 'black',
              marginTop: '20px'
            }}
          />
        )}
        <p>{t(data.body2)}</p>
      </div>
      <div className="bot-container">
        <Spacing multiplier={1} />
        <a target="_blank" href={constants.contactEmail} rel="noreferrer">
          {t('contact_us')}
        </a>
      </div>
    </div>
  )
}

export default FailedScreen
