import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { StationModel } from '../../models/station'
import { getPricingInfo, timeConvert } from '../../utils/pricing'
import styles from './Pricing.module.css'

type Props = {
  station: StationModel
}

const Pricing: FC<Props> = ({ station }) => {
  const { t } = useTranslation()
  const pricingOptions = getPricingInfo(station, t)
  const {
    pricingModel,
    free,
    superFree,
    freeTime,
    lateReturnFee,
    hasLateReturnFee,
    maxCostPerDay
  } = pricingOptions

  const getPricingText = () => {
    if (pricingModel.chargeType === 'DAILY')
      return `${pricingOptions.maxCostPerDay} / ${t('datetime.day')}`
    if (pricingModel.unlockFee > 0) {
      return t('pricing.initial', pricingOptions)
    } else {
      return t('pricing.base', pricingOptions)
    }
  }

  return (
    <div className={styles.pricingContainer}>
      {free && <h2>{t('pricing.free')} </h2>}
      {freeTime && (
        <h2>
          {t('landing_screen.free_time_rental', {
            time: timeConvert(freeTime, t)
          })}
        </h2>
      )}
      <span>
        <p className={free ? styles.free : ''}>{getPricingText()}</p>
        <p className={free ? styles.free : ''}>
          {t('pricing.maxCharge', {
            maxCostPerDay
          })}
        </p>
      </span>
      {hasLateReturnFee && (
        <p className={superFree ? styles.free : ''}>
          {t('pricing.powerbankCost', {
            lateReturnFee
          })}
        </p>
      )}
    </div>
  )
}
export default Pricing
