import React, { FC } from 'react'

import Images from '../../assets'

type Props = {
  height?: number
  white?: boolean
}

const Header: FC<Props> = ({ height = 60, white }) => {
  return (
    <div className="logo">
      <img src={white ? Images.LogoWhite : Images.Logo} height={height} alt="logo" />
    </div>
  )
}

export default Header
