import {
  TypedUseSelectorHook,
  useDispatch as useDispatchVanilla,
  useSelector as useSelectorVanilla
} from 'react-redux'

import { RootState, store } from '../redux/store'

type AppDispatch = typeof store.dispatch

export const useDispatch = (): AppDispatch => useDispatchVanilla<AppDispatch>()

export const useSelector: TypedUseSelectorHook<RootState> = useSelectorVanilla
