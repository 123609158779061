import { Promotion, StationModel } from '../models/station'
import i18n from './i18n'

const isFree = (promotion: Promotion | undefined) =>
  (promotion?.name === 'Free' || promotion?.name === 'SuperFree') && promotion.active

const isSuperFree = (promotion: Promotion | undefined) =>
  promotion?.name === 'SuperFree' && promotion.active

const isFreeTime = (promotion: Promotion | undefined) =>
  promotion?.name === 'FreeTime' && promotion.active

export const getPricingInfo = (
  station: StationModel,
  t: any
): Pick<StationModel, 'pricingModel' | 'promotion'> & {
  free: boolean
  superFree: boolean
  freeTime: number | undefined
  interval: string
  intervalPrice: string
  maxCostPerDay: string
  totalPowerBankCost: string
  initialPrice: string
  lateReturnFee: string
  reservedAmount: string
  hasLateReturnFee: boolean
} => {
  let { promotion, pricingModel } = station
  if (station.venue) {
    promotion = station.venue.promotion
    pricingModel = station.venue.pricingModel
  }
  return {
    pricingModel,
    promotion,
    free: isFree(promotion),
    superFree: isSuperFree(promotion),
    freeTime: isFreeTime(promotion) ? promotion?.freeTime : undefined,
    interval:
      pricingModel?.chargeType === 'DAILY'
        ? t('datetime.day')
        : timeConvert(pricingModel?.chargeInterval || 30, t, false),
    intervalPrice: formatMoney(pricingModel?.currency, pricingModel?.pricePerInterval),
    reservedAmount: formatMoney(
      pricingModel?.currency,
      pricingModel?.authorizationAmount ??
        (pricingModel?.pricePerInterval ?? 0) + (pricingModel?.unlockFee ?? 0)
    ),
    maxCostPerDay: formatMoney(pricingModel?.currency, pricingModel?.maxCostPerDay),
    totalPowerBankCost: formatMoney(
      pricingModel?.currency,
      pricingModel?.totalPowerBankCost
    ),
    initialPrice: formatMoney(
      pricingModel?.currency,
      (pricingModel?.pricePerInterval || 0) + (pricingModel?.unlockFee || 0)
    ),
    lateReturnFee: formatMoney(
      pricingModel?.currency,
      Math.max(pricingModel?.totalPowerBankCost - pricingModel.maxCostPerDay * 5, 0)
    ),
    hasLateReturnFee: pricingModel?.totalPowerBankCost > pricingModel?.maxCostPerDay * 5
  }
}

export const formatMoney = (currency = 'SEK', amount = 0, digits = 2) => {
  const hasFractionDigits = amount % 100 !== 0
  return Intl.NumberFormat(i18n.language, {
    style: 'currency',
    currency,
    minimumFractionDigits: hasFractionDigits ? 2 : 0,
    maximumFractionDigits: digits
  }).format(amount / 100)
}

export const timeConvert = (
  minutes: number,
  t: any,
  displayHoursIfOne = true
): string => {
  const hours = minutes / 60
  const rhours = Math.floor(hours)
  const mins = (hours - rhours) * 60
  const rminutes = Math.round(mins)
  if (hours === 1) {
    if (displayHoursIfOne) {
      return `1 ${t('datetime.hour')}`
    } else {
      return t('datetime.hour')
    }
  }
  if (hours < 1) {
    return `${minutes} ${t('datetime.minutes')}`
  }
  if (hours % 1 === 0) {
    return `${hours} ${t('datetime.hours')}`
  }
  return `${rhours} ${t('datetime.hours')} ${rminutes} ${t('datetime.minutes')}`
}
