import './Index.scss'

import React, { ChangeEvent, FC, ReactElement } from 'react'

import { LANGUAGES } from '../../utils/i18n'

type Props = {
  language: string
  changeLanguage: (e: ChangeEvent<HTMLSelectElement>) => void
}

const LanguageSwitch: FC<Props> = ({ language, changeLanguage }): ReactElement => {
  return (
    <div className="language-switcher">
      <select value={language} onChange={changeLanguage}>
        {LANGUAGES.map(lang => (
          <option key={lang.value} value={lang.value}>
            {lang.label}
          </option>
        ))}
      </select>
    </div>
  )
}

export default LanguageSwitch
