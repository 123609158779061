import React from 'react'

class ErrorBoundary extends React.Component<any, { hasError: boolean }> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: unknown) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.error('error', error, errorInfo)
  }

  render() {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>
    }

    // eslint-disable-next-line react/destructuring-assignment
    return this.props.children
  }
}

export default ErrorBoundary
