import { SyntheticEvent, useCallback, useContext, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getAuthToken } from '../api'
import { StoreContext } from '../context/storeContext'
import { useDispatch } from './useRedux'
import { setCampaignFlowLoading } from '../redux/rental/slice'

const useCreateUser = () => {
  const { i18n } = useTranslation()

  const { setAuthUser, station } = useContext(StoreContext)
  const stationRef = useRef(station)
  stationRef.current = station

  const [actionLoader, setActionLoader] = useState(false)
  const [actionErrorMessage, setActionErrorMessage] = useState('')
  const dispatch = useDispatch()

  const createUser = useCallback(
    async (e: SyntheticEvent, onSuccess: () => void | Promise<void>, body: any = {}) => {
      const isCampaign = !!stationRef.current?.venue?.organization?.campaignBeta
      e.preventDefault()
      setActionLoader(true)
      if (isCampaign) {
        dispatch(setCampaignFlowLoading(true))
      }
      const response = await getAuthToken({
        language: i18n.language,
        ...body
      })
      if (response.status) {
        if (setAuthUser && response.data) {
          setAuthUser(response.data)
        }
        setActionErrorMessage('')
        onSuccess()
      } else {
        if (setAuthUser) {
          setAuthUser({
            authToken: '',
            user: {
              id: '',
              name: '',
              promoCode: ''
            }
          })
        }
        if (response.message) {
          setActionErrorMessage(response.message)
        }
        if (isCampaign) {
          dispatch(setCampaignFlowLoading(false))
        }
      }
      setActionLoader(false)
    },
    [setAuthUser, i18n, dispatch]
  )
  return {
    createUser,
    isLoading: actionLoader,
    actionErrorMessage
  }
}

export default useCreateUser
