import PubSub from '@aws-amplify/pubsub'
import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers'
import Amplify from 'aws-amplify'

import { RentalStatusModel } from '../context/storeContext'
import { PubSubAction } from '../models/pubsub'

const Auth = {
  mandatorySignIn: false,
  identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
  region: process.env.REACT_APP_AWS_REGION,
  userPoolId: process.env.REACT_APP_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_CLIENT_ID
}

;(() => {
  try {
    Amplify.configure({ Auth })

    Amplify.addPluggable(
      new AWSIoTProvider({
        aws_pubsub_region: process.env.REACT_APP_AWS_REGION,
        aws_pubsub_endpoint: `wss://${process.env.REACT_APP_MQTT_ID}.iot.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/mqtt`
      })
    )
  } catch (e) {
    console.log('pubsub error', e)
  }
})()

let subscription: ZenObservable.Subscription

export const connectPubSub = (
  topic: string,
  setRentalStatus: (status: RentalStatusModel) => any,
  onSuccess: () => void
): void => {
  unSub()
  subscription = PubSub.subscribe(topic).subscribe({
    next: (action: PubSubAction) => {
      console.log('pubsub action: ', action)
      switch (action.value?.type) {
        case 'RENTAL_STARTED':
          setRentalStatus({ loading: false, result: 'RENTAL_STARTED', error: undefined })
          break
        case 'NO_RENTABLE_POWERBANKS':
          setRentalStatus({
            loading: false,
            result: undefined,
            error: 'NO_RENTABLE_POWERBANKS'
          })
          break
        case 'NOT_PICKED_UP_IN_TIME':
          setRentalStatus({
            loading: false,
            result: undefined,
            error: 'NOT_PICKED_UP_IN_TIME'
          })
          break
        case 'UNKNOWN_ERROR':
          setRentalStatus({
            loading: false,
            result: undefined,
            error: 'UNKNOWN_ERROR'
          })
          break
        default:
          break
      }
      onSuccess()
    },
    error: error => {
      console.log('mqtt subscription error:', error)
    },
    complete: () => console.log(' subscription Done')
  })
}

export const unSub = (): void => {
  if (subscription && !subscription.closed) {
    // subscription.unsubscribe()
  }
}

export const isSubscriptionClosed = (): boolean => !!subscription && subscription.closed
