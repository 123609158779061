import './index.scss'

import React, { FC } from 'react'

import BackgroundImage from '../../../assets/campaign-primary.png'
import RogersIcon from '../../../assets/rogers-primary-icon.svg'
import usePowerbanksUnavailable from '../../../hooks/usePowerbanksUnavailable'
import { StationModel } from '../../../models/station'
import CampaignForm from './CampaignForm'
import IconHeader from './IconHeader'
import MainSection from './MainSection'
import Slider from './Slider'

type TestProps = {
  title: string
  body1: string
  body2: string
  organization: string
  organizationImg: string
}

const campaignObject: TestProps = {
  title: 'Say “Bye Bye” to Running out of Battery!',
  body1:
    'Grab a Brick, and charge while you’re at the game and never miss a highlight again. Of course, you can bring it with you during the game, and we won’t charge a dime.',
  body2: 'Brought to you by Rogers.',
  organization: 'Rogers',
  organizationImg: RogersIcon
}

type Props = {
  station: StationModel
}

const CampaignStartScreen: FC<Props> = ({ station }) => {
  usePowerbanksUnavailable(station)
  return (
    <div className="campaign-container">
      <img
        src={BackgroundImage}
        alt="logo"
        className="campaign-container--background-image"
      />
      <IconHeader dynamicImg={campaignObject.organizationImg} />
      <section className="custom-wrapper">
        <MainSection
          main={campaignObject.title}
          body1={campaignObject.body1}
          body2={campaignObject.body2}
        />
      </section>
      <CampaignForm
        organizationTitle={campaignObject.organization}
        organizationId={station.venue?.organization?.id ?? ''}
      />
      <Slider />
    </div>
  )
}

export default CampaignStartScreen
