import './index.scss'

import React, { FC, ReactNode, useEffect } from 'react'
import { createPortal } from 'react-dom'

import { useSelector } from '../../../hooks/useRedux'

type Props = {
  children: ReactNode
}

const LoadingModal: FC<Props> = ({ children }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [])

  return createPortal(
    <div className="campaign-modal">
      <div className="wrapper">{children}</div>
    </div>,
    document.getElementById('modal')!
  )
}

const StateWrapper: FC<Props> = ({ children }) => {
  const isLoading = useSelector(state => state.rental.isLoading)
  if (!isLoading) return null

  return <LoadingModal>{children}</LoadingModal>
}

export default StateWrapper
