/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AuthUserModel } from '../../models/auth'
import { UserConfigModel } from '../../models/user'
import { authApi } from './api'

export type AuthState = {
  token?: string
  phoneNumber?: string
  verifyId?: string
  config?: UserConfigModel
  user?: AuthUserModel
}

const initialState: AuthState = {
  token: undefined,
  phoneNumber: undefined,
  config: undefined,
  verifyId: undefined,
  user: undefined
}

export const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthToken: (state, action: PayloadAction<string | undefined>) => {
      state.token = action.payload
    },
    setUserConfig: (state, action: PayloadAction<UserConfigModel | undefined>) => {
      state.config = action.payload
    },
    setPhoneNumber: (state, action: PayloadAction<string | undefined>) => {
      state.phoneNumber = action.payload
    },
    logout: state => {
      state.token = undefined
      state.phoneNumber = undefined
      state.user = undefined
      state.verifyId = undefined
      state.config = undefined
    }
  },
  extraReducers: builder => {
    builder
      .addMatcher(authApi.endpoints.getAuthToken.matchFulfilled, (state, { payload }) => {
        state.token = payload.accessToken
        state.user = payload.user
      })
      .addMatcher(
        authApi.endpoints.getAuthVerifyCode.matchFulfilled,
        (state, { payload }) => {
          state.verifyId = payload.id
        }
      )
      .addMatcher(authApi.endpoints.updateUser.matchFulfilled, (state, { payload }) => {
        state.user = payload.user
      })
  }
})

export const { setPhoneNumber, logout } = slice.actions

export default slice.reducer
