import AppStore from './AppStore.svg'
import Brick from './brick-campaign-slider.svg'
import Charge from './charge-campaign-slider.svg'
import Charging from './Charging.gif'
import CreditCard from './CreditCard.svg'
import GoBack from './GoBack.svg'
import GooglePlay from './GooglePlay.svg'
import HowTo from './HowTo.gif'
import Logo from './Logo.svg'
import LogoWhite from './LogoWhite.svg'
import NoneAvailable from './NoneAvailable.svg'
import Offline from './Offline.svg'
import Onboarding1 from './Onboarding1.svg'
import Onboarding2 from './Onboarding2.svg'
import Onboarding3 from './Onboarding3.svg'
import Onboarding4 from './Onboarding4.svg'
import Powerbank from './Powerbank.svg'
import Return from './return-campaign-slider.svg'
import Scan from './scan-campaign-slider.svg'
import Success from './Success.svg'

const Images = {
  GoBack,
  GooglePlay,
  AppStore,
  Onboarding1,
  Onboarding2,
  Onboarding3,
  Onboarding4,
  Logo,
  LogoWhite,
  CreditCard,
  Offline,
  NoneAvailable,
  Success,
  HowTo,
  Charging,
  Powerbank,
  Brick,
  Scan,
  Charge,
  Return
}

export default Images
