import React, { FC, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { routes } from '../../../App'
import Loading from '../../../components/Loading/Loading'
import { useDispatch } from '../../../hooks/useRedux'
import { useGetStationQuery } from '../../../redux/station/api'
import { setStationId } from '../../../redux/station/slice'
import { getPricingInfo } from '../../../utils/pricing'
import CampaignStartScreen from '../../appless/CampaignStartScreen'
import StartScreen from '../../appless/StartScreen'
import SignupPhoneNumber from '../../eventMode/SignupPhoneNumber'
import { useTranslation } from 'react-i18next'

const LandingScreen: FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const { stationId } = useParams<{ stationId: string }>()
  const { data: station, isLoading, isError } = useGetStationQuery(stationId)

  useEffect(() => {
    // event mode
    if (stationId) {
      dispatch(setStationId(stationId))
    }
  }, [stationId, dispatch])

  useEffect(() => {
    if (isError) {
      history.replace(routes.error)
    }
  }, [isError, history])

  if (isLoading || !station) return <Loading />

  const { superFree } = getPricingInfo(station, t)

  const isCampaign = !!station?.venue?.organization?.campaignBeta

  // campaign mode
  if (isCampaign) return <CampaignStartScreen station={station} />

  // event mode
  if (superFree) return <SignupPhoneNumber />

  // regular flow
  return <StartScreen station={station} />
}

export default LandingScreen
