import './index.scss'

import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { routes } from '../../../App'
import Images from '../../../assets'
import Button from '../../../components/Button/Button'
import HelpButton from '../../../components/HelpButton/HelpButton'
import { useSelector } from '../../../hooks/useRedux'
import { useCreateRentalMutation } from '../../../redux/rental/api'
import { useLazyGetStationQuery } from '../../../redux/station/api'
import { constants } from '../../../utils'

type Props = {}

const StartScreenFree: FC<Props> = () => {
  const history = useHistory()
  const { t } = useTranslation()

  const stationId = useSelector(state => state.station.stationId)
  const [getStation, { data: station }] = useLazyGetStationQuery()

  useEffect(() => {
    if (stationId) {
      getStation(stationId)
    }
  }, [stationId, getStation])

  const [createRental, createRentalResult] = useCreateRentalMutation()

  const user = useSelector(state => state.auth)

  useEffect(() => {
    if (station) {
      if (!station.online) {
        history.replace(routes.offline)
      } else if (station.powerBanksAvailable === 0) {
        history.replace(routes.unavailable)
      }
    }
  }, [station, history])

  const next = () => {
    if (user.token) {
      createRental({ stationId: station!.id })
      history.push(routes.starting)
    } else {
      history.push(routes.signup)
    }
  }

  return (
    <div className="start-free-screen">
      <div className="container-top">
        <img className="station-info-image" src={Images.HowTo} alt="Station" />
        <div>
          <h1>{t('landing_screen.charge_now')}</h1>
        </div>
      </div>
      <div className="container-bottom">
        <HelpButton />
        <div className="station-info-button">
          <Button
            loading={createRentalResult.isLoading}
            label={t('landing_screen.rent_button')}
            onClick={next}
            style={{ backgroundColor: '#FF024A' }}
          />
        </div>
        <a
          className="underline"
          target="_blank"
          href={constants.contactEmail}
          rel="noreferrer"
        >
          {t('contact_us')}
        </a>
      </div>
    </div>
  )
}

export default StartScreenFree
