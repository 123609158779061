import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { persistCombineReducers, persistReducer, persistStore } from 'redux-persist'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE
} from 'redux-persist/es/constants'
import { PersistConfig } from 'redux-persist/es/types'
import storage from 'redux-persist/lib/storage'

import { authApi } from './auth/api'
import authSlice, { AuthState } from './auth/slice'
import { rentalApi } from './rental/api'
import RentalSlice from './rental/slice'
import { stationApi } from './station/api'
import StationSlice from './station/slice'

const persistConfig = {
  key: 'primary',
  storage,
  whitelist: []
}
const userPersistConfig: PersistConfig<AuthState> = {
  key: 'auth',
  storage,
  whitelist: ['token', 'user']
}

const rootReducer = {
  [authApi.reducerPath]: authApi.reducer,
  auth: persistReducer(userPersistConfig, authSlice),
  [stationApi.reducerPath]: stationApi.reducer,
  station: StationSlice,
  [rentalApi.reducerPath]: rentalApi.reducer,
  rental: RentalSlice
}

const reducer = persistCombineReducers(persistConfig, rootReducer)

export const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
      .concat(authApi.middleware)
      .concat(stationApi.middleware)
      .concat(rentalApi.middleware)
})

setupListeners(store.dispatch)

export const persistor = persistStore(store)
export type RootState = ReturnType<typeof store.getState>
