import './index.scss'
import 'react-phone-input-2/lib/style.css'

import React, { ChangeEventHandler, FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { routes } from '../../../App'
import Button from '../../../components/Button/Button'
import { Spacing } from '../../../components/common'
import Header from '../../../components/Header/Header'
import { useUpdateUserMutation } from '../../../redux/auth/api'

const UpdateUserScreen: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const [updateUser, updateUserResponse] = useUpdateUserMutation()

  const [name, setName] = useState('')

  const sendConfirm = () => {
    updateUser({ name })
  }

  useEffect(() => {
    if (updateUserResponse.isSuccess) {
      history.push(routes.confirm)
    }
  }, [history, updateUserResponse.isSuccess])

  const updateName: ChangeEventHandler<HTMLInputElement> = e => {
    e.preventDefault()
    setName(e.target.value)
  }

  return (
    <div className="update-user-screen">
      <div className="top-container">
        <Header height={100} />
      </div>
      <div className="bottom-container">
        <h1>{t('update_name.set')}</h1>
        <Spacing multiplier={1} />
        <input
          value={name}
          onChange={updateName}
          placeholder="Name"
          name="name"
          inputMode="text"
          autoCapitalize="word"
          autoComplete="name"
          type="text"
        />
        <Spacing multiplier={3} />
        <div className="button-container">
          <Button
            label={t('confirm_phone.confirm')}
            disabled={!name.length}
            loading={updateUserResponse.isLoading}
            onClick={sendConfirm}
            style={{ backgroundColor: '#FF024A' }}
          />
        </div>
      </div>
    </div>
  )
}

export default UpdateUserScreen
