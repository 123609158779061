import './index.scss'

import React, { FC } from 'react'
import { isAndroid, isIOS } from 'react-device-detect'

import Images from '../../assets'
import { constants } from '../../utils'
import { Spacing } from '../common'

const AppLinks: FC = () => (
  <div className="store-icons">
    {!isAndroid && (
      <a target="_blank" href={constants.iosLink} rel="noreferrer">
        <img src={Images.AppStore} alt="App Store" />
      </a>
    )}
    {!isAndroid && !isIOS && <Spacing horizontal />}
    {!isIOS && (
      <a target="_blank" href={constants.androidLink} rel="noreferrer">
        <img src={Images.GooglePlay} alt="Google Play" />
      </a>
    )}
  </div>
)

export default AppLinks
