/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { StationModel } from '../../models/station'
import { stationApi } from './api'

export type StationState = {
  stationId?: string
  station?: StationModel
  applessOnly?: boolean
}

const initialState: StationState = {
  stationId: undefined,
  station: undefined,
  applessOnly: undefined
}

export const slice = createSlice({
  name: 'station',
  initialState,
  reducers: {
    setStationId: (state, action: PayloadAction<string | undefined>) => {
      state.stationId = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addMatcher(
        stationApi.endpoints.getStation.matchFulfilled,
        (state, { payload }) => {
          state.station = payload
        }
      )
      .addMatcher(
        stationApi.endpoints.getStationPartner.matchFulfilled,
        (state, { payload }) => {
          state.applessOnly = payload.partner.applessOnly
        }
      )
  }
})

export const { setStationId } = slice.actions

export default slice.reducer
