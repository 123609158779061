import './index.scss'

import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Images from '../../../assets'
import Button from '../../../components/Button/Button'
import { Spacing } from '../../../components/common'
import Header from '../../../components/Header/Header'
import { useSelector } from '../../../hooks/useRedux'
import { useCreateRentalMutation } from '../../../redux/rental/api'
import { constants } from '../../../utils'
import { getErrorProps } from '../../appless/FailedScreen'

const ResultScreen: FC = () => {
  const { t } = useTranslation()

  const stationId = useSelector(state => state.station.stationId)
  const status = useSelector(state => state.rental.status)

  const [completionVisible, setCompletionVisible] = useState(false)

  const [createRental, createResponse] = useCreateRentalMutation()

  const [canRetry, setCanRetry] = useState(false)

  useEffect(() => {
    let timeoutRef: NodeJS.Timeout
    if (status.loading) {
      setCanRetry(false)
    }
    if (!status.loading && status.error) {
      timeoutRef = setTimeout(() => {
        setCanRetry(true)
      }, 5000)
    }

    return () => clearTimeout(timeoutRef)
  }, [status.error, status.loading])

  const tryAgain = () => {
    createRental({ stationId: stationId! })
  }

  useEffect(() => {
    let timeoutRef: NodeJS.Timeout
    if (!status.loading && status.result === 'RENTAL_STARTED') {
      setCompletionVisible(true)
      timeoutRef = setTimeout(() => {
        setCompletionVisible(false)
      }, 3000)
    }
    return () => clearTimeout(timeoutRef)
  }, [status])

  if (status.loading) {
    return (
      <div className="result-loading-container">
        <h1>{t('completed.loading')}</h1>
        <img src={Images.HowTo} alt="loading" />
        <div />
      </div>
    )
  }

  if (status.error) {
    const errorProps = getErrorProps(status.error)
    return (
      <div className="result-failed-container">
        <div className="top-container">
          <img src={errorProps.img} alt={errorProps.imgAlt} />
        </div>
        <div className="mid-container">
          <h1>{t(errorProps.header)}</h1>
          <Spacing multiplier={2} />
          <p>{t(errorProps.body1)}</p>
          <Spacing multiplier={5} />
          <Button
            label={t('failed_screen.try_again')}
            onClick={tryAgain}
            disabled={!canRetry}
            style={{ backgroundColor: '#FF024A' }}
          />
        </div>
        <div className="bot-container">
          <a target="_blank" href={constants.contactEmail} rel="noreferrer">
            {t('contact_us')}
          </a>
          <Spacing multiplier={3} />
        </div>
      </div>
    )
  }

  if (completionVisible) {
    return (
      <div className="result-success-container">
        <img src={Images.Success} alt="Success" />
        <Spacing multiplier={2} />
        <h1>{t('completed.success1')}</h1>
        <p>{t('completed.success2')}</p>
      </div>
    )
  }

  return (
    <div className="result-completed-container">
      <div className="top-container">
        <Header height={100} white />
        <h1>{t('completed.header')}</h1>
        <p>{t('completed.body1')}</p>
      </div>
      <div className="mid-container">
        <h2> {t('result.return_reminder')}</h2>
      </div>
      <div className="bottom-container">
        <Spacing multiplier={1} />
        <div className="contact-container">
          <a target="_blank" href={constants.contactEmail} rel="noreferrer">
            {t('contact_us')}
          </a>
        </div>
        <Spacing multiplier={3} />
      </div>
    </div>
  )
}

export default ResultScreen
