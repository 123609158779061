import { createApi } from '@reduxjs/toolkit/query/react'

import { UserAuthModel } from '../../models/auth'
import { fetchBase } from '../baseQuery'

export const rentalApi = createApi({
  reducerPath: 'rentalApi',
  keepUnusedDataFor: 0,
  baseQuery: fetchBase(),
  endpoints: builder => ({
    createRental: builder.mutation<UserAuthModel, { stationId: string }>({
      query: data => ({
        url: `/rental`,
        method: 'POST',
        body: data
      })
    })
  })
})

export const { useCreateRentalMutation } = rentalApi
