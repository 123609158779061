import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryMeta
} from '@reduxjs/toolkit/query/react'

import { RootState } from './store'

const baseUrl = process.env.REACT_APP_BASE_URL
const apiKey = process.env.REACT_APP_API_KEY ?? ''

export type ErrorResponse = {
  data: {
    error: {
      message: string
      statusCode?: number
      err?: any
      function?: string
      body?: any
      userId?: string
      method?: string
      path?: string
      metaData?: { [key: string]: any }
    }
  }
  status: number
}

export const fetchBase = (): BaseQueryFn<
  string | FetchArgs,
  unknown,
  ErrorResponse,
  {},
  FetchBaseQueryMeta
> =>
  fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const { token } = (getState() as RootState).auth
      if (token) {
        headers.set('Authorization', token)
      }
      headers.set('x-api-key', apiKey)
      headers.set('app-version', 'WEB:1.6.1')
      return headers
    }
  }) as BaseQueryFn<string | FetchArgs, unknown, ErrorResponse, {}, FetchBaseQueryMeta>
