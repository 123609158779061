import React, {
  createContext,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useHistory } from 'react-router-dom'

import { startRental } from '../api'
import { connectPubSub, unSub } from '../api/pubsub'
import { routes } from '../App'
import { useDispatch, useSelector } from '../hooks/useRedux'
import { StoreContextModel } from '../models/general'
import { UserAuthModel } from '../models/user'
import {
  useLazyGetStationPartnerQuery,
  useLazyGetStationQuery
} from '../redux/station/api'
import { setCampaignFlowLoading } from '../redux/rental/slice'

// @ts-ignore
export const StoreContext = createContext<StoreContextModel>({})

export type RentalStatusModel = {
  loading: boolean
  error?:
    | string
    | 'UNKNOWN_ERROR'
    | 'NOT_PICKED_UP_IN_TIME'
    | 'NO_RENTABLE_POWERBANKS'
    | 'TIMED_OUT'
  result?: 'RENTAL_STARTED'
}

const StoreContextProvider: FC = ({ children }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [authUser, setAuthUser] = useState<UserAuthModel>({
    authToken: '',
    user: {
      id: '',
      name: '',
      promoCode: ''
    }
  })
  const authUserRef = useRef(authUser)
  authUserRef.current = authUser

  const [rentalStatus, setRentalStatus] = useState<RentalStatusModel>({
    loading: false
  })

  const stationId = useSelector(state => state.station.stationId)
  const [getStation, { data: station }] = useLazyGetStationQuery()
  const [getPartner] = useLazyGetStationPartnerQuery()

  useEffect(() => {
    if (stationId) {
      getStation(stationId)
    }
  }, [stationId, getStation])

  useEffect(() => {
    if (station?.market) getPartner(station.market)
  }, [station, getPartner])

  useEffect(() => {
    if (authUser?.user?.id) {
      connectPubSub(authUser.user.id, setRentalStatus, () =>
        dispatch(setCampaignFlowLoading(false))
      )
    }
    return () => unSub()
  }, [authUser?.user?.id, dispatch])

  const timeoutRef = useRef<NodeJS.Timeout>()

  const clearFailTimeout = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
  }, [])

  const createRental = useCallback(
    async (route: string = routes.result) => {
      setRentalStatus({ loading: true, result: undefined, error: undefined })
      clearFailTimeout()
      const createRentalRes = await startRental(
        authUserRef.current.authToken,
        station!.id
      )
      if (createRentalRes.status) {
        timeoutRef.current = setTimeout(() => {
          setRentalStatus(prev => {
            if (prev.result || prev.error) {
              return prev
            }

            return {
              error: 'TIMED_OUT',
              result: undefined,
              loading: false
            }
          })
          dispatch(setCampaignFlowLoading(false))
        }, 20000)
        history.push(route)
      } else {
        setRentalStatus({ error: createRentalRes.message, loading: false })
        dispatch(setCampaignFlowLoading(false))
      }
    },
    [station, history, clearFailTimeout, dispatch]
  )

  useEffect(() => {
    if (rentalStatus.error || rentalStatus.result) {
      clearFailTimeout()
    }
  }, [rentalStatus, clearFailTimeout])

  useEffect(() => {
    if (rentalStatus.error) {
      history.replace(routes.failed)
    }
  }, [rentalStatus, history])

  useEffect(() => clearFailTimeout, [clearFailTimeout])

  const value = useMemo(
    () => ({
      authUser,
      setAuthUser,
      rentalStatus,
      setRentalStatus,
      createRental,
      station
    }),
    [authUser, setAuthUser, rentalStatus, setRentalStatus, createRental, station]
  )

  return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
}

export default StoreContextProvider
