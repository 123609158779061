import './index.scss'

import React, { FC } from 'react'

import BrickIcon from '../../../assets/brick-icon.svg'
import VerticalDivider from '../../../assets/vertical-divider.svg'

type Props = {
  dynamicImg?: string
  reverse?: boolean
}

const IconHeader: FC<Props> = ({ dynamicImg, reverse }) => {
  return (
    <section className={`header-icons ${reverse && 'header-icons--reverse'}`}>
      <img src={dynamicImg} alt="rogers" className="header-icons--1" />
      <img src={VerticalDivider} alt="divider" className="header-icons--2" />
      <img src={BrickIcon} alt="brick" className="header-icons--3" />
    </section>
  )
}

export default IconHeader
