import './index.scss'

import React, {
  ChangeEvent,
  FC,
  SyntheticEvent,
  useContext,
  useMemo,
  useState
} from 'react'

import ErrorMessage from '../../../components/Error/Error'
import { StoreContext } from '../../../context/storeContext'
import useCreateUser from '../../../hooks/useCreateUser'
import { constants, validateEmail } from '../../../utils'
import Button from './Button'

const initErrors = {
  email: '',
  terms: ''
}

type Props = {
  organizationTitle: string
  organizationId: string
}

const CampaignForm: FC<Props> = ({ organizationTitle, organizationId }) => {
  const [errors, setErrors] = useState(initErrors)
  const [form, setForm] = useState({
    email: '',
    news: false,
    terms: false
  })

  const { createRental } = useContext(StoreContext)

  const { createUser, isLoading, actionErrorMessage } = useCreateUser()

  const validEmail = useMemo(() => validateEmail(form.email), [form.email])

  const onChangeInputValues = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'news' || e.target.name === 'terms') {
      setForm({
        ...form,
        [e.target.name]: e.target.checked
      })
    } else {
      setForm({
        ...form,
        [e.target.name]: e.target.value
      })
    }
  }

  const setErrorMessages = () => {
    const newErrors = { ...initErrors }
    if (!form.email) {
      newErrors.email = 'Email is required'
    } else if (!validEmail) {
      newErrors.email = 'Incorrect email format'
    }

    if (!form.terms) {
      newErrors.terms = '*Terms and Conditions must be accepted to use the service'
    }
    return setErrors(newErrors)
  }

  const submitForm = (e: SyntheticEvent) => {
    e.preventDefault()
    setErrorMessages()

    const validForm = form.terms && validEmail
    if (!validForm) return null

    const data = {
      contactEmail: form.email,
      ...(form.news && {
        acceptEmailFrom: {
          id: organizationId,
          type: 'ORGANIZATION'
        }
      })
    }

    createUser(e, createRental, data)
    return null
  }

  return (
    <section className="form-wrapper">
      <form className="campaign-form" onSubmit={submitForm}>
        <label className="campaign-form__text-label">
          Email*
          <input
            type="email"
            name="email"
            autoComplete="email"
            className={`campaign-form__text-input ${
              form.email && !validEmail ? 'campaign-form__text-input--error' : ''
            }`}
            onChange={onChangeInputValues}
          />
        </label>
        <label className="campaign-form__checkbox-label">
          I want news and offers from {organizationTitle}
          <input
            type="checkbox"
            name="news"
            className="campaign-form__checkbox-input"
            onChange={onChangeInputValues}
          />
        </label>
        <label className="campaign-form__checkbox-label">
          I accept the{' '}
          <span>
            <a href={constants.terms} target="_blank" rel="noreferrer">
              Terms and Conditions*
            </a>
          </span>
          <input
            type="checkbox"
            name="terms"
            className="campaign-form__checkbox-input"
            onChange={onChangeInputValues}
          />
        </label>

        <Button type="submit" id="placeholder-analytics" btnType="primary">
          Start rental
        </Button>
      </form>
      {errors.terms && <p className="campaign-form__error">{errors.terms}</p>}
      {errors.email && <p className="campaign-form__error">{errors.email}</p>}
      {actionErrorMessage && <ErrorMessage message={actionErrorMessage} />}
    </section>
  )
}

export default CampaignForm
