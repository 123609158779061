import './Index.scss'

import React, { FC, SyntheticEvent } from 'react'

type Props = {
  label: string
  onClick: (e: SyntheticEvent) => void
  loading?: boolean
  disabled?: boolean
  style?: React.CSSProperties
}

const Button: FC<Props> = ({ label, onClick, loading, disabled, style }) => {
  return (
    <button
      className="button-with-loader"
      disabled={disabled || loading}
      type="button"
      style={style}
      onClick={onClick}
    >
      {label}
      {loading && (
        <div className="action-loader-wrapper">
          <div className="action-loader" />
        </div>
      )}
    </button>
  )
}

export default Button
