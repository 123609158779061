import ModalBackground from '../../../assets/modal-background.png'
import IconHeader from './IconHeader'
import RogersIcon from '../../../assets/rogers-primary-icon.svg'
import Loading from '../../../components/Loading/Loading'
import React from 'react'

// temporary shit
const LoadingRoger = () => {
  return (
    <>
      <img src={ModalBackground} alt="logo" className="wrapper--background-image" />
      <IconHeader dynamicImg={RogersIcon} />
      <h3 className="wrapper--main">Loading</h3>
      <Loading minHeight />
    </>
  )
}

export default LoadingRoger
