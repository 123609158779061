import { createApi } from '@reduxjs/toolkit/query/react'

import { StationModel } from '../../models/station'
import { PartnerModel } from '../../models/market'
import { fetchBase } from '../baseQuery'

export const stationApi = createApi({
  reducerPath: 'stationApi',
  keepUnusedDataFor: 180,
  baseQuery: fetchBase(),
  endpoints: builder => ({
    getStation: builder.query<StationModel, string>({
      query: stationId =>
        `/stations/${stationId}?includeVenue=true&includeOrganization=true`
    }),
    getStationPartner: builder.query<{ partner: PartnerModel }, string>({
      query: market => `/market/partner/${market}`
    })
  })
})

export const {
  useGetStationQuery,
  useLazyGetStationQuery,
  useLazyGetStationPartnerQuery
} = stationApi
