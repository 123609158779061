import './index.scss'
import 'react-phone-input-2/lib/style.css'

import React, { FC, useEffect, useState } from 'react'
import ReactCodeInput from 'react-code-input'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { routes } from '../../../App'
import Button from '../../../components/Button/Button'
import { Spacing } from '../../../components/common'
import Header from '../../../components/Header/Header'
import { useSelector } from '../../../hooks/useRedux'
import { useGetAuthTokenMutation } from '../../../redux/auth/api'

const ConfirmCodeScreen: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [getAuthToken, authResponse] = useGetAuthTokenMutation()
  const phoneNumber = useSelector(state => state.auth.phoneNumber)
  const verifyId = useSelector(state => state.auth.verifyId)

  const [code, setCode] = useState('')

  const sendConfirm = () => {
    getAuthToken({
      verificationCode: code,
      phoneNumber,
      id: verifyId
    })
  }

  const goBack = () => {
    history.goBack()
  }

  useEffect(() => {
    const name = authResponse?.data?.user?.name
    if (name && name !== 'Brick user') {
      history.push(routes.confirm)
    } else if (authResponse.data && !authResponse.isLoading) {
      history.push(routes.name)
    }
  }, [authResponse, history])

  return (
    <div className="confirm-code-screen">
      <Header height={100} white />
      <div>
        <h1>{t('confirm_phone.message')}</h1>
        <Spacing multiplier={1} />
        <p>{t('confirm_phone.code')}</p>
        <p className="phone-number">{phoneNumber}</p>
      </div>
      <Spacing multiplier={1.5} />
      <ReactCodeInput
        value={code}
        onChange={setCode}
        fields={6}
        name="codeInput"
        inputMode="numeric"
      />
      <Spacing multiplier={1.5} />
      <div className="button-container">
        <Button
          label={t('confirm_phone.confirm')}
          loading={authResponse.isLoading}
          onClick={sendConfirm}
          style={{ backgroundColor: '#FF024A' }}
        />
      </div>
      <Spacing multiplier={1.5} />
      <div className="button-container">
        <Button
          label={t('onboarding.go_back')}
          onClick={goBack}
          style={{
            backgroundColor: 'transparent',
            border: 'black solid 2px',
            color: 'black'
          }}
        />
      </div>
    </div>
  )
}

export default ConfirmCodeScreen
